/* eslint-disable @typescript-eslint/no-explicit-any */
// Global
import { Formik, Form as FormikForm, FormikHelpers, FormikProps } from 'formik';
// Utils and Form Helper
import TextField from 'src/helpers/Form/TextField';
import { FormFieldsProps, transformData } from 'src/utils/formUtils';
import { useState, useEffect, useRef } from 'react';
// Tailwind Import
import billingAddress from 'tailwindVariants/components/billingTailwindVariant';
import CheckboxField from 'src/helpers/Form/CheckboxField';
import { ComponentProps } from 'lib/component-props';
import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import DropdownField from 'src/helpers/Form/DropdownField';
import useDictionary from 'src/hooks/useDictionary';
import { BuyerAddress, Me } from 'ordercloud-javascript-sdk';
import { useOcSelector, useOcDispatch } from 'src/redux/ocStore';
import { saveBillingAddress } from 'src/redux/ocCurrentOrder';
import deliveryAddress from 'tailwindVariants/components/deliveryAddressTailwindVariant';
import { useCheckoutFormContext } from 'lib/context/CheckoutFormContext';
import {
  DiscreteLineItem,
  FulfillmentType,
  GTMLabels,
  GTM_EVENT,
  addressType,
} from 'src/helpers/Constants';
import useOcCart from 'src/hooks/useOcCart';
import { LineItemWithXp } from 'src/redux/xp';
import AutoCompleteWrapper from 'src/helpers/Form/AutoCompleteWrapper';
import clsx from 'clsx';
import { getGTMSessionStorage, sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { ProductSearchResultModelWithVariants } from 'src/helpers/search/SearchResults/types';
import Loader from 'components/Loader/Loader';
import { useCartPriceForUI } from 'src/hooks/useCartPriceForUI';
import { saveAddress } from 'src/redux/ocAddressBook';

export type BillingAddressProps = React.InputHTMLAttributes<HTMLInputElement> &
  ComponentProps & {
    fields: {
      data: {
        data: {
          title: Field<string>;
          shortDescription: Field<string>;
          submitButtonText: Field<string>;
          successMessage: Field<string>;
          successRedirectUrl?: { jsonValue: LinkField };
          failureMessage?: Field<string>;
          consentMessage?: Field<string>;
          errors: ErrorField;
          formFields: {
            targetItems: Array<FormFieldsProps>;
          };
        };
        states?: {
          regions: {
            targetItems: State[];
          };
        };
      };
    };
  };
interface ErrorField {
  values: {
    name: string;
    value: string;
  }[];
}
interface State {
  code: { value: string };
  name: { value: string };
}

interface FormValuesTypes {
  ID?: string;
  FirstName: string;
  LastName: string;
  AddressLine1: string;
  AddressLine2: string;
  City: string;
  State: string;
  Zip: string;
  SaveToProfile?: string;
}
const BillingAddress = ({ fields }: BillingAddressProps): JSX.Element => {
  // Tailwind Variant
  const {
    base,
    title,
    form,
    pickupInformationContainer,
    validFieldInfo,
    inlineFields,
    submitBtn,
    loaderWrapper,
    fieldWrapper,
    linkWithUnderline,
    blockTitle,
    profileCheckbox,
    sameDelivery,
  } = billingAddress({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const { titleWrapper, addressBlock } = deliveryAddress({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  // Get form-fields

  const transFormFields = transformData(fields.data?.data?.formFields);
  const { getDictionaryValue } = useDictionary();
  const [loading, setLoading] = useState<boolean>(false);
  const billingAddressForm = useRef<FormikProps<FormValuesTypes>>(null);
  const { activateStep, expandedStep, goToNextStep } = useCheckoutFormContext();

  const formRef = useRef<HTMLDivElement>(null);

  const collapse = expandedStep !== 'billing';

  const [disableFields, setDisableFields] = useState(false);
  const [isShippingEventFired, setIsShippingEventFired] = useState(false);

  const autoCompleteFieldMap = {
    addressLine1: 'AddressLine1',
    addressLine2: 'AddressLine2',
    city: 'City',
    stateCode: 'State',
    zipcode: 'Zip',
  };

  const empty = <div id="billingaddress" className="billingaddress" hidden></div>;
  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const meBillingAddress = useMeBillingAddress();
  const cart = useOcSelector((state) => state?.ocCurrentOrder?.order);
  const currentOrder = useOcSelector((state) => state?.ocCurrentOrder);
  const billingAdd = useOcSelector((state) => state?.ocCurrentOrder?.order?.BillingAddress);
  const ocCurrentOrder = useOcSelector((state) => state?.ocCurrentOrder);
  const { getProductLineItems } = useOcCart();
  const productlineitems: LineItemWithXp[] = getProductLineItems();
  const shippingAddress = productlineitems?.[0]?.ShippingAddress;

  const initialValues = useInitialValues();

  //For GTM
  const timeoutIdRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const dispatch = useOcDispatch();

  const pickup: boolean = cart?.xp?.Fulfillment === FulfillmentType.BOPIS;

  const billingAddValid =
    billingAdd?.FirstName &&
    billingAdd?.LastName &&
    billingAdd?.Street1 &&
    billingAdd?.City &&
    billingAdd?.State &&
    billingAdd?.Zip;

  const initialValuesValid =
    initialValues?.FirstName &&
    initialValues?.LastName &&
    initialValues?.AddressLine1 &&
    initialValues?.City &&
    initialValues?.State &&
    initialValues?.Zip;

  /**
   * Logic to automatically save the Billing form info if user is authenticated
   */
  useEffect(() => {
    if (
      !isAnonymous &&
      ocCurrentOrder?.initialized == true &&
      ocCurrentOrder.order &&
      !billingAddValid &&
      initialValuesValid
    ) {
      // Call Save BillingAddress and collapse it.
      submitBillingAddress(initialValues, false);
    }
  }, [ocCurrentOrder?.initialized, billingAddValid, initialValuesValid, isAnonymous]);

  const openBillingForm = async () => {
    sendShippingEvent();

    activateStep('billing');

    // Remove Loader and Restore Submit Button
    setLoading(false);
  };

  const submitBillingAddress = async (
    values: FormValuesTypes | undefined,
    fromUserAction: boolean
  ) => {
    setLoading(true);
    try {
      const ocAddress = {
        ID: values?.ID,
        FirstName: values?.FirstName,
        LastName: values?.LastName,
        Street1: values?.AddressLine1,
        Street2: values?.AddressLine2,
        City: values?.City,
        State: values?.State,
        Zip: values?.Zip,
        Country: 'US',
        Billing: true,
        AddressName: addressType?.billing,
      };
      if (values?.SaveToProfile && !isAnonymous) {
        await dispatch(saveAddress({ ...ocAddress, ID: meBillingAddress?.ID }));
      }
      // Send data to OC to save Billing address
      await dispatch(saveBillingAddress(ocAddress));

      if (fromUserAction) {
        goToNextStep('billing');
      }
    } catch (error) {
      console.error('submitPickupAddress: ', error);
    }
    setLoading(false);
  };

  const handleCopy = (
    event: any,
    setValues: FormikHelpers<FormValuesTypes>['setValues'],
    values: FormValuesTypes
  ) => {
    if (event.target.checked) {
      if (shippingAddress) {
        setValues(
          {
            FirstName: shippingAddress?.FirstName ?? values?.FirstName,
            LastName: shippingAddress?.LastName ?? values?.LastName,
            AddressLine1: shippingAddress?.Street1,
            AddressLine2: shippingAddress?.Street2 ?? '',
            City: shippingAddress?.City,
            State: shippingAddress?.State || '',
            Zip: shippingAddress?.Zip,
            SaveToProfile: 'true',
          },
          true
        );
      }
      setDisableFields(true);
      // disable whole form
    } else {
      // enable whole form
      setDisableFields(false);
    }
  };

  const { currentPage, pageItem, position } = getGTMSessionStorage();

  const cartPricing = useCartPriceForUI(currentOrder);

  const sendShippingEvent = () => {
    setIsShippingEventFired(true);
    const productLineItems = ocCurrentOrder?.lineItems?.filter(
      (x) => ![DiscreteLineItem.TIP].includes(x.ProductID)
    );

    const productData = productLineItems?.map((lineItem) => {
      const products = {
        ...lineItem?.Product,
        quantity: lineItem?.Quantity,
        BasePrice: lineItem?.UnitPrice,
        listPrice: lineItem?.UnitPrice,
      };
      return products;
    });

    productData &&
      sendProductsPromotion({
        eventName: GTM_EVENT?.addShippingInfo,
        data: productData as unknown as ProductSearchResultModelWithVariants[],
        currentPage: currentPage,
        pageItem: pageItem,
        position: position ?? 0,
        storeId: myStoreData?.storeId,
        currency: true,
        ShippingTier: !pickup ? GTMLabels?.firstTime : GTMLabels?.pickup,
        totalCount: Number(cartPricing?.subTotal),
        fulfillment_option: !pickup ? GTMLabels?.DFS : GTMLabels?.BOPIS,
      });
  };

  const productData = productlineitems?.map((lineItem) => {
    const products = {
      ...lineItem?.Product,
      quantity: lineItem?.Quantity,
      BasePrice: lineItem?.UnitPrice,
      listPrice: lineItem?.UnitPrice,
    };
    return products;
  });

  useEffect(() => {
    setIsShippingEventFired(false);
  }, [cart?.xp?.Fulfillment]);

  useEffect(() => {
    const checkGtmLoad = () => {
      const isBeginCheckoutFired =
        window &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any)['dataLayer']?.filter((val: any) => val?.event === GTM_EVENT?.beginCheckout)
          ?.length > 0;

      const isGTMLoad =
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        typeof window !== 'undefined' && (window as any)['google_tag_manager']?.dataLayer?.gtmLoad;
      if (
        isBeginCheckoutFired &&
        isGTMLoad &&
        productData &&
        cart?.xp?.Fulfillment &&
        !isShippingEventFired &&
        (cart?.BillingAddress || cart?.xp?.PickupInfo)
      ) {
        sendShippingEvent();
      } else {
        timeoutIdRef.current = setTimeout(() => {
          checkGtmLoad();
        }, 1000);
      }
    };

    checkGtmLoad();

    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [cart?.xp?.PickupInfo, isShippingEventFired]);

  if (!myStoreData || !cart) {
    return empty;
  }
  return (
    <div
      id="billingaddress"
      ref={formRef}
      className={'billingaddress ' + base({ isCollapsed: collapse })}
    >
      <div className={titleWrapper()}>
        <div className={title({ isCollapsed: collapse })}>
          {pickup ? '2.' : '3.'} {fields?.data?.data?.title?.value}
        </div>
        {collapse && billingAdd && (
          <div className={linkWithUnderline() + ' cursor-pointer'} onClick={openBillingForm}>
            Edit
          </div>
        )}
      </div>
      {collapse && billingAdd && (
        <div className={addressBlock()}>
          <span className={blockTitle()}>Bill To:</span>
          <p className="block">
            {initialValues?.FirstName} {initialValues?.LastName}
          </p>
          <span className="block">
            {initialValues?.AddressLine1} {initialValues?.AddressLine2}
          </span>
          <span className="block">
            {initialValues?.City}, {initialValues?.State} {initialValues?.Zip}
          </span>
        </div>
      )}
      {/* Fields marked with an asterisk * .... */}
      {!collapse && fields?.data?.data?.shortDescription?.value && (
        <div className={fieldWrapper()}>
          <div className={validFieldInfo()}>{fields?.data?.data?.shortDescription?.value}</div>
        </div>
      )}
      {!collapse && (
        <Formik<FormValuesTypes>
          initialValues={initialValues}
          innerRef={billingAddressForm}
          onSubmit={(values: FormValuesTypes) => {
            submitBillingAddress(values, true);
          }}
        >
          {({ setValues, values }) => (
            <FormikForm className={pickupInformationContainer()}>
              <div className={pickupInformationContainer()}>
                <div className={form()}>
                  {!pickup && transFormFields.sameasdelivery && (
                    <div className={clsx(fieldWrapper(), sameDelivery())}>
                      {
                        <CheckboxField
                          {...transFormFields.sameasdelivery}
                          singleCheckbox={true}
                          onClick={(event) => handleCopy(event, setValues, values)}
                        />
                      }
                    </div>
                  )}
                  {/* First Name and Last Name */}
                  <div className={inlineFields()}>
                    {transFormFields.FirstName && (
                      <div className={fieldWrapper()}>
                        <TextField {...transFormFields.FirstName} disabled={disableFields} />
                      </div>
                    )}
                    {transFormFields.LastName && (
                      <div className={fieldWrapper()}>
                        <TextField {...transFormFields.LastName} disabled={disableFields} />
                      </div>
                    )}
                  </div>
                  {/* Address Line1 and Address Line2 */}
                  <div className={inlineFields()}>
                    {transFormFields.AddressLine1 && (
                      <div className={fieldWrapper()}>
                        <AutoCompleteWrapper fieldMap={autoCompleteFieldMap}>
                          {({}: any) => {
                            return (
                              <>
                                <TextField
                                  {...transFormFields.AddressLine1}
                                  disabled={disableFields}
                                />
                              </>
                            );
                          }}
                        </AutoCompleteWrapper>
                      </div>
                    )}
                    {transFormFields.AddressLine2 && (
                      <div className={fieldWrapper()}>
                        <TextField {...transFormFields.AddressLine2} disabled={disableFields} />
                      </div>
                    )}
                  </div>
                  {/* City and State */}
                  <div className={inlineFields()}>
                    {transFormFields.City && (
                      <div className={fieldWrapper()}>
                        <TextField {...transFormFields.City} disabled={disableFields} />
                      </div>
                    )}
                    {transFormFields.State && (
                      <div className={fieldWrapper()}>
                        <DropdownField
                          {...transFormFields.State}
                          options={fields.data?.states?.regions?.targetItems}
                          firstOptionData={getDictionaryValue('SelectState')}
                          disabled={disableFields}
                        />
                      </div>
                    )}
                  </div>
                  {/* Zip Field */}
                  {transFormFields.Zip && (
                    <div className={fieldWrapper()}>
                      <TextField {...transFormFields.Zip} disabled={disableFields} />
                    </div>
                  )}
                  {/* Save to Profile */}
                  {!isAnonymous && transFormFields.SaveToProfile && (
                    <div className={clsx(fieldWrapper(), profileCheckbox())}>
                      {<CheckboxField {...transFormFields.SaveToProfile} singleCheckbox={true} />}
                    </div>
                  )}
                  <div className={fieldWrapper()}>
                    {loading ? (
                      <div className={loaderWrapper()}>
                        <Loader />
                        {getDictionaryValue('ScheduleAppointmentsStep2SelectDogInProgressText')}
                      </div>
                    ) : (
                      <>
                        {fields.data?.data?.submitButtonText?.value && (
                          <button aria-label="submit" className={submitBtn()} type="submit">
                            {fields.data?.data?.submitButtonText?.value}
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </FormikForm>
          )}
        </Formik>
      )}
    </div>
  );
};
export default BillingAddress;

function useMeBillingAddress() {
  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);
  const [meBillingAddress, setMeBillingAddress] = useState<BuyerAddress>();

  useEffect(() => {
    async function getAddresses() {
      const listAdd = await Me.ListAddresses({ sortBy: ['DateCreated'] });
      const meBillingAddress = listAdd.Items?.find(
        (x) => x?.AddressName === 'Billing' && x?.Billing === true
      );
      setMeBillingAddress(meBillingAddress);
    }
    if (isAnonymous) {
      setMeBillingAddress(undefined);
    } else {
      getAddresses();
    }
  }, []);

  return meBillingAddress;
}

function useInitialValues(): FormValuesTypes {
  const orderBillingAddress = useOcSelector(
    (state) => state?.ocCurrentOrder?.order?.BillingAddress
  );

  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);
  const meBillingAddress = useMeBillingAddress();

  if (orderBillingAddress) {
    return {
      FirstName: orderBillingAddress?.FirstName ?? '',
      LastName: orderBillingAddress?.LastName ?? '',
      AddressLine1: orderBillingAddress?.Street1 ?? '',
      AddressLine2: orderBillingAddress?.Street2 ?? '',
      City: orderBillingAddress?.City ?? '',
      State: orderBillingAddress?.State ?? '',
      Zip: orderBillingAddress?.Zip ?? '',
      SaveToProfile: 'true',
    };
  }

  if (isAnonymous) {
    return {
      FirstName: '',
      LastName: '',
      AddressLine1: '',
      AddressLine2: '',
      City: '',
      State: '',
      Zip: '',
      SaveToProfile: 'true',
    };
  }
  if (meBillingAddress) {
    return {
      ID: meBillingAddress.ID,
      FirstName: meBillingAddress?.FirstName ?? '',
      LastName: meBillingAddress?.LastName ?? '',
      AddressLine1: meBillingAddress?.Street1 ?? '',
      AddressLine2: meBillingAddress?.Street2 ?? '',
      City: meBillingAddress?.City ?? '',
      State: meBillingAddress?.State ?? '',
      Zip: meBillingAddress?.Zip ?? '',
      SaveToProfile: 'true',
    };
  }

  return {
    FirstName: '',
    LastName: '',
    AddressLine1: '',
    AddressLine2: '',
    City: '',
    State: '',
    Zip: '',
    SaveToProfile: 'true',
  };
}
