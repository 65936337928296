import { Field, LinkField, Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { useCallback, useMemo, useState } from 'react';
import CheckoutPaymentVariant from 'tailwindVariants/components/checkoutPaymentTailwindVariant';
import clsx from 'clsx';
import { useTheme } from 'lib/context/ThemeContext';
import useOcCurrentOrder from '../../../hooks/useOcCurrentOrder';
import { nanoid } from '@reduxjs/toolkit';
import { apiRequest } from 'src/utils/apiWrapper';
import { AdyenClientApiWrapper, ZeroAuthResponse } from 'lib/adyen/client/api-wrapper';
import { useRef, useEffect, RefObject } from 'react';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import styles from './styles/CheckoutPayment.module.css';
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin/Dropin';
import { BillingAddress } from '@adyen/api-library/lib/src/typings/checkout/billingAddress';
import useOcAuth from 'src/hooks/useOcAuth';
import { useCookies } from 'react-cookie';
import { Tokens } from 'ordercloud-javascript-sdk';
import { setRecentPlacedOrder } from 'src/redux/ocCurrentOrder';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { useRouter } from 'next/router';
import useOcCart from 'src/hooks/useOcCart';
import { OrderWithXp, OrderWorksheetWithXP } from 'src/redux/xp';
import { FulfillmentType, GTMLabels, GTM_EVENT, errorMessages } from 'src/helpers/Constants';
import { getGTMSessionStorage, sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { ProductSearchResultModelWithVariants } from 'src/helpers/search/SearchResults/types';
import { onSubmitValidateData } from '../helper/preflight';
import Loader from 'components/Loader/Loader';
import useDictionary from 'src/hooks/useDictionary';
import { useCartPriceForUI } from 'src/hooks/useCartPriceForUI';
import { useCheckoutFormContext } from 'lib/context/CheckoutFormContext';
import { checkoutAPI } from 'src/utils/nextApiConfig';

export type CheckoutPaymentProps = React.InputHTMLAttributes<HTMLInputElement> &
  ComponentProps & {
    fields?: {
      title?: Field<string>;
      submitButtonText?: Field<string>;
      successMessage?: Field<string>;
      successRedirectUrl?: {
        jsonValue: LinkField;
      };
      failureMessage?: Field<string>;
      errors?: ErrorField;
    };
  };

interface ErrorField {
  values: [];
}

interface OrderSubmitResponse {
  worksheet?: OrderWorksheetWithXP;
  error?: string;
}

const CheckoutPayment = ({ fields, rendering }: CheckoutPaymentProps): JSX.Element => {
  const {
    base,
    form,
    paymentInformationContainer,
    // defaultTitle,
    // title,
    submitBtn,
    // titleWrapper,
    loaderWrapper,
    fieldWrapper,
  } = CheckoutPaymentVariant({
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const router = useRouter();
  const dispatch = useOcDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const { checkoutContextData, updateCheckoutContextData, paymentOpen, expandedStep } =
    useCheckoutFormContext();

  const collapse = !paymentOpen;

  const [error, setError] = useState<string>('');
  const cart = useOcSelector((state) => state?.ocCurrentOrder?.order);
  const { getProductLineItems } = useOcCart();
  const productlineitems = getProductLineItems();

  useEffect(() => {
    if (document.body.classList.contains('overflow-hidden')) {
      document.body.classList.remove('overflow-hidden');
    }
  }, []);

  const paymentContainer = useRef<HTMLDivElement>(null);

  const adyenCheckout = useCheckout(paymentContainer);
  const { themeNameUpper } = useTheme();
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const currentOrder = useOcSelector((state) => state?.ocCurrentOrder);

  const [cookies] = useCookies(['ordercloud.access-token']);
  const { getDictionaryValue } = useDictionary();

  const hasAutoship =
    !!currentOrder.order?.xp?.AutoshipFrequency && currentOrder.order.xp.AutoshipFrequency > 0;

  const invokeSubmitOrder = async () => {
    if (
      hasAutoship &&
      cartPricing?.autoshipSubTotal &&
      myStoreData?.deliveryFee?.minimumAmount &&
      myStoreData?.deliveryFee?.minimumAmount > cartPricing?.autoshipSubTotal
    ) {
      updateCheckoutContextData({ showAutoshipWarning: true });
      return;
    }
    setLoading(true);
    setError('');
    const token = cookies['ordercloud.access-token']
      ? cookies['ordercloud.access-token']
      : Tokens.GetAccessToken();
    const submitocorderUrl = checkoutAPI?.submitorder;
    const isValid = adyenCheckout.validateCheckout();
    //Commenting as its not part of Sprint4
    if (!isValid || !cart?.ID) {
      setLoading(false);
      return;
    }
    /*
    if (!ocPayment || !ocPayment.length) {
      const payment: Payment = { ID: cart?.ID ?? '', Type: 'CreditCard' };
      await dispatch(addPayment(payment));
    }
    */
    try {
      const preflightResponse = await onSubmitValidateData(currentOrder); // Function to call to check the preflights conditions:
      if (!preflightResponse) {
        console.error(
          'Some required fields is missing on your form data. Kindly revisit them and populate in order to proceed ahead with payment.'
        );
        return;
      }

      // Submit check out to Adyen
      const zeroauthResponse = await adyenCheckout.submitCheckout();
      if (zeroauthResponse?.paymentResponse) {
        if (zeroauthResponse?.paymentResponse?.resultCode !== 'Authorised') {
          adyenCheckout.remount();
          setLoading(false);
          return;
        }
      }

      const headersData = {
        Authorization: token,
        site: themeNameUpper,
      };
      const payload = {
        orderId: cart.ID,
        zeroauthResponse: zeroauthResponse?.paymentResponse,
        encryptedSecurityCode: zeroauthResponse?.encryptedSecurityCode,
      };
      const options = { method: 'POST', headers: headersData, data: payload };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: OrderSubmitResponse = await apiRequest<OrderSubmitResponse>(
        submitocorderUrl,
        options
      );

      if (response?.error) {
        // reading from dictionary and fallback from constant as end users should not be seeing exact error
        console.warn(response?.error);
        setError(getDictionaryValue('GenericPaymentError') ?? errorMessages?.genericPaymentError);
      }
      const storedPaymentMethodId =
        zeroauthResponse?.paymentResponse?.additionalData?.['recurring.recurringDetailReference'];
      const shopperReference =
        zeroauthResponse?.paymentResponse?.additionalData?.['recurring.shopperReference'];
      if (response?.worksheet) {
        if (!zeroauthResponse?.storePaymentMethod && storedPaymentMethodId && shopperReference) {
          await AdyenClientApiWrapper.deleteStoredPaymentMethod(
            storedPaymentMethodId,
            shopperReference
          );
        }

        await dispatch(setRecentPlacedOrder(response?.worksheet));
        if (response?.worksheet?.Order?.ID) {
          const orderNumber = response?.worksheet?.Order?.xp?.OrderId;
          const redirectUrl =
            fields?.successRedirectUrl?.jsonValue?.value?.href ??
            `/checkout/orderconfirmation?id=${orderNumber}`;
          router.push(redirectUrl);
        }
      } else {
        //delete card if it is not using stored payment and faced an error scenario:
        if (storedPaymentMethodId && shopperReference && !zeroauthResponse?.storePaymentMethod) {
          await AdyenClientApiWrapper.deleteStoredPaymentMethod(
            storedPaymentMethodId,
            shopperReference
          );
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      //console.error('OrderSubmit Respose', err);
      // reading from dictionary and fallback from constant as end users should not be seeing exact error
      console.warn(err.message);
      setError(getDictionaryValue('GenericPaymentError') ?? errorMessages?.genericPaymentError);
    } finally {
      setLoading(false);
    }

    // TODO: Should be redirected to the '/checkout/orderconfirmation' page
  };

  //GTM data layer integration
  const pickup: boolean = cart?.xp?.Fulfillment === FulfillmentType.BOPIS;

  const { position } = getGTMSessionStorage();
  const cartPricing = useCartPriceForUI(currentOrder);

  const productData = productlineitems?.map((lineItem) => {
    const products = {
      ...lineItem?.Product,
      quantity: lineItem?.Quantity,
      BasePrice: lineItem?.UnitPrice,
      listPrice: lineItem?.UnitPrice,
    };
    return products;
  });

  useEffect(() => {
    const checkGtmLoad = () => {
      const isBeginCheckoutFired =
        window &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any)['dataLayer']?.filter((val: any) => val?.event === GTM_EVENT?.beginCheckout)
          ?.length > 0;

      const isShippingFired =
        window &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any)['dataLayer']?.filter((val: any) => val?.event === GTM_EVENT?.beginCheckout)
          ?.length > 0;

      const isGTMLoad =
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        typeof window !== 'undefined' && (window as any)['google_tag_manager']?.dataLayer?.gtmLoad;

      if (
        isBeginCheckoutFired &&
        isShippingFired &&
        !collapse &&
        productData &&
        isGTMLoad &&
        expandedStep === 'none'
      ) {
        sendProductsPromotion({
          eventName: GTM_EVENT?.addPaymentInfo,
          data: productData as ProductSearchResultModelWithVariants[],
          position: position ?? 0,
          storeId: myStoreData?.storeId,
          currency: true,
          ShippingTier: !pickup ? GTMLabels?.firstTime : GTMLabels?.pickup,
          totalCount: Number(cartPricing?.subTotal),
          fulfillment_option: !pickup ? GTMLabels?.DFS : GTMLabels?.BOPIS,
        });
      } else {
        setTimeout(() => {
          checkGtmLoad();
        }, 1000);
      }
    };

    !collapse && productData && checkGtmLoad();
  }, [pickup, collapse, expandedStep]);

  if (!myStoreData || Object.keys(myStoreData).length === 0 || !cart) {
    return (
      <div className="checkoutpayment" hidden>
        {cart?.xp?.Fulfillment}
      </div>
    );
  }
  return (
    <>
      {!collapse && (
        <div id="checkoutpayment" className={clsx('checkoutpayment', base({ active: collapse }))}>
          {/* <div className={titleWrapper()}>
          <Text
            field={
              fields?.title || {
                value: pickup ? '3. Payment Information' : '4. Payment Information',
              }
            }
            className={clsx(collapse ? defaultTitle() : title())}
            tag="p"
          />
        </div> */}
          <Placeholder name="checkout-before-payment" rendering={rendering} />
          <div
            className={clsx(paymentInformationContainer(), { hidden: collapse, block: !collapse })}
          >
            <div className={form()}>
              <div className={styles.paymentContainer}>
                {adyenCheckout.loadingPaymentForm ? <Loader /> : null}
                <div ref={paymentContainer} className="payment"></div>
              </div>
              <div className={fieldWrapper()}>
                {/* Submit Button */}
                {loading ? (
                  <div className={loaderWrapper()}>
                    <Loader />
                    <p>{getDictionaryValue('placingOrder')}</p>
                  </div>
                ) : (
                  <>
                    <button
                      aria-label="submit"
                      className={submitBtn()}
                      type="submit"
                      onClick={invokeSubmitOrder}
                    >
                      {fields?.submitButtonText?.value || 'Place Order'}
                    </button>
                    {/* Earlier version used to show error */}
                    {/* {error ? <p className="mt-4 text-color-accent-red">{error}</p> : <></>} */}
                    {/* After implementing CVV decline change  */}
                    {checkoutContextData?.CheckoutError !== undefined ? (
                      <></>
                    ) : (
                      error && <p className="mt-4 text-color-accent-red">{error}</p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CheckoutPayment;

type SubmitPaymentPromise = {
  promise?: Promise<ZeroAuthResponse>;
  resolve?: (value: ZeroAuthResponse) => void;
  reject?: (reason?: unknown) => void;
};

const useCheckout = (paymentContainer: RefObject<HTMLDivElement>) => {
  const checkoutComponent = useRef<DropinElement>();
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const { order } = useOcCurrentOrder();

  const [loadingPaymentForm, setLoadingPaymentForm] = useState(false);
  // We need a useRef here because we don't reinitialize the payment component
  // so it can be holding on to an older copy of order because it's not a native React component
  const orderRef = useRef<OrderWithXp>();
  orderRef.current = order;

  const auth = useOcAuth();
  const { checkoutContextData, updateCheckoutContextData, activateStep, paymentOpen } =
    useCheckoutFormContext();

  // TODO ensure data is coming from store
  const adyenClientKey =
    (myStoreData?.clientKey as string) ?? 'test_WIVENFRNNRBUDFJFGT7GO65WWYWSD4HO';
  function validateCheckout() {
    checkoutComponent.current?.showValidation();

    const isValid = checkoutComponent.current?.isValid;

    return isValid;
  }

  function remount() {
    checkoutComponent.current?.update({});
  }

  // Get the order from ref because otherwise in a callback scenario
  // it can have a cached copy of the value rather than the latest current value
  function getIsAutoship() {
    const orderFromRef = orderRef.current ?? order;
    return !!orderFromRef?.xp?.AutoshipFrequency && orderFromRef.xp.AutoshipFrequency > 0;
  }
  const isAutoship = getIsAutoship();

  // Keep previous state in a useRef.  Because we don't need
  // to watch for changes and this is based off DOM elements
  // we are using a useRef instead of useState
  const storePaymentCheckboxOriginalStateRef = useRef(false);
  // Checkbox for whether to save payment method
  const storePaymentCheckboxRef = useRef<HTMLInputElement>();
  // If order is autoship, ensure that the store payment method checkbox is checked
  // and disable to prevent unchecking
  useEffect(() => {
    const elem = storePaymentCheckboxRef.current;
    if (elem) {
      if (isAutoship) {
        storePaymentCheckboxOriginalStateRef.current = elem.checked;
        elem.checked = true;
        elem.disabled = true;
      } else {
        elem.checked = storePaymentCheckboxOriginalStateRef.current;
        elem.disabled = false;
      }
    }
  }, [isAutoship]);

  const [submitPromise, setPromise] = useState<SubmitPaymentPromise>();

  const resetPromise = useCallback(() => {
    if (!submitPromise) {
      return;
    }
    // Promise to return on submit
    const promise = new Promise<ZeroAuthResponse>((resolve, reject) => {
      submitPromise.resolve = resolve;
      submitPromise.reject = reject;
    });
    submitPromise.promise = promise;
  }, [submitPromise]);

  useEffect(() => {
    if (!submitPromise) {
      const newSubmitPromise: SubmitPaymentPromise = {};
      // Promise to return on submit
      const promise = new Promise<ZeroAuthResponse>((resolve, reject) => {
        newSubmitPromise.resolve = resolve;
        newSubmitPromise.reject = reject;
      });
      newSubmitPromise.promise = promise;
      setPromise(newSubmitPromise);
      resetPromise();
    }
  }, [resetPromise, submitPromise]);

  async function submitCheckout() {
    checkoutComponent.current?.submit();
    return await submitPromise?.promise;
  }

  const orderUser = order?.FromUser;
  const billingAddress = order?.BillingAddress;

  const amount = useMemo(
    () => ({
      currency: 'USD',
      // Value needs to be in cents
      // value: (order?.Total ?? 0) * 100,
      value: 0,
    }),
    [order?.Total]
  );

  const shopperData = useMemo(
    () =>
      auth.isAnonymous || !orderUser?.ID
        ? {
            shopperReference: nanoid(),
            shopperName: `${billingAddress?.FirstName} ${billingAddress?.LastName}`.trim(),
            shopperEmail: '',
          }
        : {
            shopperReference: orderUser.ID,
            shopperName: `${orderUser.FirstName} ${orderUser.LastName}`.trim(),
            shopperEmail: orderUser.Email,
          },
    [
      auth.isAnonymous,
      billingAddress?.FirstName,
      billingAddress?.LastName,
      orderUser?.Email,
      orderUser?.FirstName,
      orderUser?.ID,
      orderUser?.LastName,
    ]
  );

  const billing: BillingAddress = useMemo(
    () => ({
      street: billingAddress?.Street1 ?? '',
      // Checked against their OOTB implementation.  For US-based address this part is the "Apartment / Suite (optional)" field.
      houseNumberOrName: billingAddress?.Street2 ?? '',
      city: billingAddress?.City ?? '',
      postalCode: billingAddress?.Zip ?? '',
      stateOrProvince: billingAddress?.State ?? '',
      country: billingAddress?.Country ?? '',
    }),
    [
      billingAddress?.City,
      billingAddress?.Country,
      billingAddress?.State,
      billingAddress?.Street1,
      billingAddress?.Street2,
      billingAddress?.Zip,
    ]
  );
  useEffect(() => {
    let ignore = false;

    const merchantReference = order?.ID;
    const createCheckout = async () => {
      const translations = {
        'en-US': {
          'creditCard.cvcField.title': 'CVC/CVV',
          storeDetails: 'Save Credit Card for Future Use',
        },
      };
      const paymentMethodsResponse = await AdyenClientApiWrapper.getPaymentMethods(
        'en-US',
        amount,
        shopperData.shopperReference
      );
      const checkout = await AdyenCheckout({
        paymentMethodsResponse,
        locale: 'en-US',
        translations,
        environment: process.env.NEXT_PUBLIC_ADYEN_ENVIRONMENT?.toLocaleLowerCase() ?? 'test',
        showPayButton: false,

        clientKey: adyenClientKey,

        paymentMethodsConfiguration: {
          card: {
            showStoredPaymentMethods: !auth.isAnonymous,
            enableStoreDetails: !auth.isAnonymous,

            // Called when Card UI is loaded
            onConfigSuccess: (e) => {
              // Find the checkbox and store it in ref
              const elem = e.rootNode.querySelector<HTMLInputElement>('[name="storeDetails"]');
              storePaymentCheckboxRef.current = elem ?? undefined;

              // Get latest value
              const isAutoship = getIsAutoship();
              if (elem) {
                // Create a span for a label.  We'll toggle visibility in CSS
                // based on whether the checkbox is disabled
                const label = elem.nextElementSibling;
                const span = document.createElement('span');
                span.className = 'store-card-autoship-message text-gray-400';
                span.innerText = ' (Autoship orders must save card)';
                label?.appendChild(span);

                if (isAutoship) {
                  elem.checked = true;
                  elem.disabled = true;
                }
              }
            },
            // billingAddressRequired: true,
          },
        },

        onSubmit: (state /*, component*/) => {
          if (state.isValid) {
            const data = {
              ...state.data,
              ...shopperData,
              billing,
              merchantReference,
            };

            // Get the order from the ref to ensure it is the most current copy
            const isAutoship = getIsAutoship();

            // Always store for autoship
            if (isAutoship) {
              data.storePaymentMethod = true;
            }

            AdyenClientApiWrapper.initiatePayment(data, amount, isAutoship)
              .then((data) => {
                //handleServerResponse(data, component);//Instead of showing payment success on Zero auth, its will be good to show success on full auth.

                // used for CVC Declined
                if (data?.paymentResponse?.refusalReasonCode === '24') {
                  updateCheckoutContextData({ CheckoutError: true });
                  // Transition from down to top:
                  activateStep('paymentError');
                }
                submitPromise?.resolve && submitPromise.resolve(data);
                resetPromise();
              })
              .catch((e) => {
                submitPromise?.reject && submitPromise.reject(e);
                resetPromise();
              });
          } else {
            submitPromise?.reject && submitPromise.reject(state);
            resetPromise();
          }
        },
        // TODO figure out if we need this
        onAdditionalDetails: (state, component) => {
          console.info('onAdditionalDetails ', state, component);
        },

        // TODO figure out if we need this, doesn't appear to ever be called.
        onPaymentCompleted: (data) => {
          console.log('Success: ' + data.resultCode);
        },
        // TODO figure out when this is called, do better handling
        onError: (error) => {
          console.error(error);

          // alert('an error occurred: ' + error);
        },
      });

      // The 'ignore' flag is used to avoid double re-rendering caused by React 18 StrictMode
      // More about it here: https://beta.reactjs.org/learn/synchronizing-with-effects#fetching-data
      if (paymentContainer.current && !ignore) {
        const dropinUI = checkout
          .create('dropin', {
            showRemovePaymentMethodButton: true,
            onDisableStoredPaymentMethod: (
              storedPaymentMethodId: string,
              resolve: (typeof Promise)['resolve'],
              reject: (typeof Promise)['reject']
            ) => {
              AdyenClientApiWrapper.deleteStoredPaymentMethod(
                storedPaymentMethodId,
                shopperData.shopperReference
              )
                .then(resolve)
                .catch(reject)
                .then(() => {
                  // TODO trigger click even for Cards so it auto expands.
                  console.info('payment method deleted');
                });
            },
          })
          .mount(paymentContainer.current);
        setLoadingPaymentForm(false);

        checkoutComponent.current = dropinUI;
      }
    };

    /**
     * While initiating the payment drop-in only when the active form
     *  is set to Payment, significantly reduces the unwanted number of calls
     */
    if (paymentOpen) {
      // It's already been created, but in some scenarios it gets unmounted
      // Remount it to make sure it's present.
      if (
        checkoutComponent.current &&
        paymentContainer.current &&
        !paymentContainer.current.children.length
      ) {
        checkoutComponent.current.mount(paymentContainer.current);
      } else {
        setLoadingPaymentForm(true);
        createCheckout();
      }
    }

    return () => {
      ignore = true;
    };
  }, [
    adyenClientKey,
    amount,
    billing,
    shopperData,
    orderRef.current,
    paymentContainer,
    submitPromise,
    paymentOpen,
    checkoutContextData?.CheckoutError,
  ]);

  return {
    submitCheckout,
    validateCheckout,
    remount,
    loadingPaymentForm,
  };
};
